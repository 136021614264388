export function showDialogError(message) {
  Swal.fire({
    title: '注意',
    text: message,
    icon: 'warning',
    iconColor: '#d63443',
    confirmButtonText: 'はい',
    customClass: {
      confirmButton: 'btn btn-danger',
    }
  });
}

export function showDeleteConfirmation(options, callback) {
  const defaultOptions = {
    text: '情報を削除しますか',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'いいえ',
    confirmButtonText: 'はい',
    reverseButtons: true,
    iconColor: '#d63443',
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-light',
    },
    buttonsStyling: false
  };

  const swalOptions = { ...defaultOptions, ...options };

  Swal.fire(swalOptions).then((result) => {
    if (result.isConfirmed && typeof callback === 'function') {
      callback();
    }
  });
}

export function showDialogSuccess(message) {
  Swal.fire({
    text: message,
    icon: 'success',
    confirmButtonText: 'はい',
    customClass: {
      confirmButton: 'btn btn-info',
    }
  });
}

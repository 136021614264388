$(function () {
  $(document).on("cocoon:after-insert", "#user-pic-company-form", function (e, insertedItem) {
    $(".icon-remove-user-company").removeClass("d-none");
  });

  $(document).on("cocoon:after-remove", "#user-pic-company-form", function (e, insertedItem) {
    detectHideRemovePicUserCompany();
  });

  function bindValueForBankForm($container) {
    let bank_branch_id = $container.find('.bank_branch_id').attr('selected_id');
    let bankId = $container.find('.partner-company-bank-id').attr('selected_id')
    let is_temporary = $container.find('.is_temporary').attr('value');

    if (is_temporary.length > 0) {
      $container.find(`.is_temporary input[value=${is_temporary}]`).prop('checked', true);
    }

    setTimeout(() => {
      $container.find('.partner-company-bank-id select').val(bankId).change();
    }, 100);

    setTimeout(() => {
      $container.find('.bank_branch_id select').val(bank_branch_id).change();
    }, 600);
  }

  detectHideRemovePicUserCompany();

  function formState($form, isActive = true) {
    if (isActive) {
      $form.find('input').prop('disabled', false);
      $form.find('select').prop('disabled', false);
      $form.find('a').removeClass('disabled');
    } else {
      $form.find('a').addClass('disabled');
      $form.find('input').prop('disabled', true);
      $form.find('select').prop('disabled', true);
    }

    $form.find('#company_client_company_attributes_kintone_code').prop('disabled', true);
    $form.find('#_hidden_field_client_kinton_code').prop('disabled', false);
    $form.find('#company_partner_company_attributes_kintone_code').prop('disabled', true);
  }
  window.formState = formState;
  window.bindValueForBankForm = bindValueForBankForm;

  if ($('.companies-form').length > 0) {
    setTimeout(() => {
      $(`#bank_data > .display-data-temp`).each(function () {
        bindValueForBankForm($(this).find('.temp-form'))
      })
    }, 200);
  }

  if ($('.companies-form').length > 0) {
    formState($('.client-form'), $('#company_is_client_company').is(':checked'))
    // if ($('#company_is_client_company').is(':checked')) {
    //   $('.client-form').find(".client_company_kintone_code").removeClass('d-none');
    // } else {
    //   $('.client-form').find(".client_company_kintone_code").addClass('d-none');
    // }
    formState($('.partner-company-form'), $('#company_is_partner_company').is(':checked'))
  }

  function detectHideRemovePicUserCompany() {
    if ($(".wrap-pic-user-company").length == 1) {
      $(".icon-remove-user-company").addClass("d-none");
    }
  }

  $(document).on('change', '.parent_company_sel', function () {
    if ($(this).val() == '') {
      $(this).closest('.row').find('#company_bill_this_company').attr('disabled', true).attr('checked', false);
    } else {
      $(this).closest('.row').find('#company_bill_this_company').attr('disabled', false);
    }
  })

  $(document).on('change', '.company_id', function () {
    var company_id = $(this).val();
    var element = $('#parent_company');
    $.ajax({
      method: "get",
      dataType: 'json',
      url: `/companies/${company_id}/get_parent_companies`,
      success: function (e) {
        $(element).empty();
        let data = e.companies.map(function (item) {
          return { id: item.id, text: item.name }
        })

        $(element).select2({
          width: '100%',
          theme: 'bootstrap',
          placeholder: '選択してください',
          allowClear: true,
          data: data,
          language: {
            noResults: function () {
              return '見つかりません。';
            },
          },
          dropdownParent: $('#modal-bank-form')
        });
        if (e.parent_company != '') {
          $(element).val(e.parent_company).change();
        }
      }
    })
  })

  $(document).on('click', '#partner-company-bank-form .is_temporary input', function () {
    let isTemporary = $(this).closest('span').text()
    $(this).closest('.is_temporary').attr('value', $(this).val())
    $(this).closest('#partner-company-bank-form').find('.is_temporary_val').text(isTemporary);
  })

  $(document).on('change', '#partner-company-bank-form .name_kanji input', function () {
    let name_kanji = $(this).val();
    $(this).attr('value', name_kanji)
    $(this).closest('#partner-company-bank-form').find('.transfer-name-kanji').text(name_kanji);
  })

  $(document).on('change', '#partner-company-bank-form .name_kana', function () {
    let name_kana = $(this).val();
    $(this).attr('value', name_kana)
    $(this).closest('#partner-company-bank-form').find('.transfer-name-kana').text(name_kana);
  })

  $(document).on('change', '#partner-company-bank-form .account_number', function () {
    let accountNumber = $(this).val();
    $(this).attr('value', accountNumber)
    $(this).closest('#partner-company-bank-form').find('.account-number').text(accountNumber);
  })

  $(document).on('change', '#partner-company-bank-form .bank_branch_id select', function () {
    let branchName = $(this).find('option:selected').text();
    $(this).closest('.bank_branch_id').attr('selected_id', $(this).val())
    $(this).closest('#partner-company-bank-form').find('.branch-name').text(branchName);
  })

  $(document).on('change', '.partner-company-bank-id select', function () {
    let bank_id = $(this).val();
    $(this).closest('.partner-company-bank-id').attr('selected_id', $(this).val())
    let bankName = $(this).find('option:selected').text();
    $(this).closest('#partner-company-bank-form').find('.bank-name').text(bankName);
    var $element = $(this)

    $.ajax({
      url: `/banks/${bank_id}/branches`,
      type: 'GET',
      success: function (e) {
        let data = e.branches.map(function (item) {
          return { id: item[0], text: item[1] }
        })

        $bank_branch_id = $element.closest('#modal-bank-form').find('.bank_branch_id select');
        // clear old options
        $bank_branch_id.empty();
        $bank_branch_id.select2({
          width: '100%',
          theme: 'bootstrap',
          placeholder: '選択してください',
          allowClear: true,
          data: data,
          language: {
            noResults: function () {
              return '見つかりません。';
            },
          },
          dropdownParent: $('#modal-bank-form')
        });

        $bank_branch_id.val('').change();
      }
    });
  });

  $(document).on('change', '.position-company-type', function() {
    var abbreviation = $('#company_type_id').find('option:selected').data('abbreviation');
    var company_type = $('#company_type_id').find('option:selected').val();
    var company_text = $('#company_type_id').find('option:selected').text();
    var position = $('#juridical_personality_position').find('option:selected').val();
    var code = $('#company_code_base').val();
    var name = $('#company_name').val();
    var name_base = $('#name_base').val() || name;
    if (position && company_type) {          
      switch(position) {        
        case 'prefix':
          code = `${code} ${abbreviation} ${name}`;
          name_base = `${company_text} ${name_base}`;
          break;
        case 'appendix':
          code = `${code} ${name} ${abbreviation}`;
          name_base = `${name_base} ${company_text}`;
          break;
        default:
          break;
      }
    }
    $('#company_company_code').val(code);
    $('#company_code').val(code);
    $('#company_company_name').val(name_base)
  })

  $(document).on('change', '#company_name', function() {
    var company_text = $('#company_type_id').find('option:selected').text();
    var position = $('#juridical_personality_position').find('option:selected').val();
    var name = $('#company_name').val();
    var name_base = $('#name_base').val() || name;
    if (position && company_text) {          
      switch(position) {        
        case 'prefix':
          name_base = `${company_text} ${name_base}`;
          break;
        case 'appendix':
          name_base = `${name_base} ${company_text}`;
          break;
        default:
          break;
      }
    }
    $('#company_company_name').val(name_base)
  })

  $(document).on('change', '.legal_change', function() {
    var name = $('#current_name').val();
    var position = $('#main_company_juridical_personality_position').find('option:selected').val();
    var company_type = $('#company_type_id').find('option:selected').val();
    var company_name = '';
    if (position && company_type) {
      switch(position) {        
        case 'prefix':
          company_name = `${company_type} ${name}`;
          $('#main_company_name').val(company_name)
          break;
        case 'appendix':
          company_name = `${name} ${company_type}`;
          $('#main_company_name').val(company_name)
          break;
        default:
          $('#main_company_name').val(name)
          break;
      }
    }    
  })

  $(document).on('change', '#main_company_name', function() {
    var position = $('#main_company_juridical_personality_position').find('option:selected').val();
    var company_type = $('#company_type_id').find('option:selected').val();
    var company_name = $(this).val();
    var base_name = company_name;
    if (position && company_type) {
      switch(position) {        
        case 'prefix':
          base_name = company_name.split(" ").slice(1).join(" ");          
          break;
        case 'appendix':
          base_name = company_name.split(" ").slice(0, -1).join(" ");          
          break;
        default:
          break;
      }
    }
    $('#current_name').val(base_name)
  })

})

$(document).on('change', '.form-common-page #payment_type', function () {
  var value = $(this).val();
  if (value == 'transfer_deposit') {
    $('.form-common-page #payment_day').val('99').change();
    $('.form-common-page #payment_month_type').val('this_month').change();
  } else if (value == 'direct_debit') {
    $('.form-common-page #payment_day').val('27').change();
  }
})

$(document).on('input', '.input-kanji-only', function () {
  this.value = this.value.replace(/[^一-龯]/g, '');
});

$(document).on('input', '.input-kana-only', function () {
  this.value = this.value.replace(/[^ぁ-んァ-ン]/g, '');
});

$(document).on('click', '.form-common-page .btn-add-bank', function () {  
  let formId = Math.floor(Math.random() * 16777215)
  $('#modal-bank-form').data('mode', 'create');
  $('#modal-bank-form').data('formId', formId);
  $('#modal-bank-form').modal('show');
});

$(document).on('hidden.bs.modal', '#modal-bank-form', function () {
  $('#partner-company-bank-form').html('')
});

$(document).on('click', '#modal-bank-form-submit', function (event) {
  event.preventDefault();
  // get all input in form
  let formId = $('#partner-company-bank-form .display-data-temp').attr('form-id');
  $('#partner-company-bank-form select').each(function () {
    if ($(this).data('select2')) {
      $(this).select2('destroy');
    }
  });
  let $existForm = $(`#bank_data > .display-data-temp[form-id=${formId}]`);
  if ($existForm.length > 0) {
    $existForm.html($('#partner-company-bank-form .temp-container .display-data-temp').html());
  } else {
    htmlValueDisplay = $('#partner-company-bank-form .temp-container').html()
    $('#bank_data').append(htmlValueDisplay)
  }

  let rawFormHtml = $('#partner-company-bank-form').html();
  $tempForm = $(`#bank_data .display-data-temp[form-id=${formId}] > .temp-form`)
  $tempForm.attr('form-html', rawFormHtml);
  $tempForm.attr('escaped_html', false);
  $tempForm.html(rawFormHtml)
  bindValueForBankForm($tempForm)
  $('#modal-bank-form').modal('hide');
})

$(document).on('click', '#bank_data .btn-edit', function () {
  let $container = $(this).closest('.display-data-temp').find('.temp-form')
  let formHtml = $container.attr('form-html')
  if ($container.attr('escaped_html') == 'true') {
    formHtml = $('<div/>').html(formHtml).text()
  }

  let formId = Math.floor(Math.random() * 16777215)
  $(this).closest('.display-data-temp').attr('form-id', formId);
  $('#partner-company-bank-form').html(formHtml);
  $('#partner-company-bank-form select').each(function () {
    if (!$(this).hasClass('select2-hidden-accessible')) {
      $('#partner-company-bank-form select').select2({
        width: "100%",
        theme: "bootstrap",
        placeholder: "選択してください",
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
        dropdownParent: $('#modal-bank-form')
      });
    }
  });      
  bindValueForBankForm($('#partner-company-bank-form'))
  $('#modal-bank-form').modal('show');
  $('#partner-company-bank-form .display-data-temp').attr('form-id', formId);
})

$(document).on('click', '#bank_data .btn-delete', function () {
  $(this).closest('.display-data-temp').hide()
  // find destroy-checkbox and checked
  $(this).closest('.display-data-temp').find('.temp-form .destroy-checkbox').attr('value', 'true')
})

$(document).on('change', '#company_is_partner_company', function () {
  let $form = $('.partner-company-form')
  formState($form, $(this).is(':checked'))
})

$(document).on('change', '#company_is_client_company', function () {
  let $form = $('.client-form')
  formState($form, $(this).is(':checked'))
  $form.find(".client_company_kintone_code").removeClass('d-none');
})

$(document).on('change', '.company_client_company_client_receive_id .select2', function () {
  if($(this).val() !== '') {
    $.ajax({
      method: "get",
      dataType: 'json',
      url: `/client_companies/${$(this).val()}`,
      success: function (e) {
        $('.company_client_company_apl .select2').val(e.apl).change();
        $('.company_client_company_pca .select2').val(e.pca).change();
        $('.company_client_company_closing_day .select2').val(e.closing_day).change();
        $('.company_client_company_payment_type .select2').val(e.payment_type).change();
        $('.company_client_company_payment_day .select2').val(e.payment_day).change();
        $('.company_client_company_payment_month_type .select2').val(e.payment_month_type).change();
        $('.company_client_company_pay_status .select2').val(e.pay_status).change();
        $('#_hidden_field_client_kinton_code').val(e.kintone_code)
        $('#company_client_company_attributes_kintone_code').val(e.kintone_code)
      }
    })    
  } else {
    $('.company_client_company_apl .select2').val(null).change();
    $('.company_client_company_pca .select2').val(null).change();
    $('.company_client_company_closing_day .select2').val(null).change();
    $('.company_client_company_payment_type .select2').val(null).change();
    $('.company_client_company_payment_day .select2').val(null).change();
    $('.company_client_company_payment_month_type .select2').val(null).change();
    $('.company_client_company_pay_status .select2').val(null).change();
    var companycode = $('#company_company_code').val();
    $('#_hidden_field_client_kinton_code').val('CL-' + companycode.split(' ')[0]);
    $('#company_client_company_attributes_kintone_code').val('CL-' + companycode.split(' ')[0]);
  }
})

$(document).ready(function() {
  $('#modal-bank-form').on('shown.bs.modal', function () {
    const mode = $(this).data('mode');
    const formId = $(this).data('formId');
    if (mode === 'create') {
      $('.partner-company-bank-id select, .bank_branch_id select').css('visibility', 'hidden');
      $('#partner-company-bank-form .display-data-temp').attr('form-id', formId);
      $('.partner-company-bank-id select').select2({
        width: "100%",
        theme: "bootstrap",
        placeholder: "選択してください",
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
        dropdownParent: $('#modal-bank-form')
      });
    
      $('.bank_branch_id select').select2({
        width: "100%",
        theme: "bootstrap",
        placeholder: "選択してください",
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
        dropdownParent: $('#modal-bank-form')
      });
    }
  });

  $('#modal-bank-form').on('hidden.bs.modal', function () {
    $(this).removeData('mode');
  });
})
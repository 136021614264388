$(document).ready(function () {
  $("#main_company_postal_code, #company_zipcode").inputFilter(function(value) {
    return /^[0-9_.-]*$/.test(value);    // Allow digits only, using a RegExp
  },"Only digits allowed");

  $("#main_company_postal_code, #company_zipcode").on('change', function(){
    regex = /^[0-9]{3}-[0-9]*$/;
    newVal = formatzipcode($(this).val().replace(/[^0-9 ]/g, ""));

    if(regex.test(newVal)) {
      $(this).val(newVal);
      $('#search_address').prop('disabled', false);
    } else {
      $(this).val('')
      $('#search_address').prop('disabled', true);
    }
  });

  $("#file-upload-logo, #file-upload").on('change', function(){
    if((this.files[0].size/ 1024 / 1024) > 2) {
      $(this).closest('.logo-group').append('<div class="text-danger mt-2">アップロードするファイルのサイズは2MBを超えることはできません</div>')
      $('.btn-save').prop('disabled', true);
    } else {
      if($('.logo-group').find('.text-danger').length > 0){
        $('.btn-save').prop('disabled', true);
      } else {
        $('.btn-save').prop('disabled', false);
      }
    }
  });

  function formatzipcode(val) {
    return val.slice(0, 3) + '-' + val.slice(3);
  }
})

$.fn.inputFilter = function(callback, errMsg) {
  return this.on("input keydown keyup mousedown mouseup select contextmenu drop focusout", function(e) {
    if (callback(this.value)) {
      // Accepted value
      if (["keydown","mousedown","focusout"].indexOf(e.type) >= 0){
        $(this).removeClass("input-error");
        this.setCustomValidity("");
      }
      this.oldValue = this.value;
      this.oldSelectionStart = this.selectionStart;
      this.oldSelectionEnd = this.selectionEnd;
    } else if (this.hasOwnProperty("oldValue")) {
      // Rejected value - restore the previous one
      // $(this).addClass("input-error");
      // this.setCustomValidity(errMsg);
      this.reportValidity();
      this.value = this.oldValue;
      this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
    } else {
      // Rejected value - nothing to restore
      this.value = "";
    }
  });
};

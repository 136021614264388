import { showDialogError, showDialogSuccess, showDeleteConfirmation } from '../sweealert2';

$(document).ready(function () {
  $(document).on('cocoon:after-insert', '#project-client-company, #wrap-problem-company, #sale_business', function (e, insertedItem) {
    let $select2Elm = $(insertedItem).find(".form-select.select2");
    _.forEach($select2Elm, function ($elm) {
      $($elm).select2({
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });
    });
  });

  $(document).on('cocoon:after-insert', '#wrap-problem-company, #sale-info-table, #project-client-company', function (e, insertedItem) {
    initDateRangePicker();
  });


  function initDateRangePicker() {
    $('input.singledate').daterangepicker({
      locale: ja_date_locale,
      singleDatePicker: true,
      autoUpdateInput: false,
      autoApply: true,
    });

    $('input.singledate').on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    $('input.singledate').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });
  }

  $('.form-client-company #client_company_company_id').on('change', function () {
    let companyId = $(this).val();

    const listId = [
      'client_company_note',
      'assigned_industry_ids',
      'name_kana',
      'kintone_code',
      'name',
      'representative_name',
      'zipcode',
      'capical',
      'client_company_company_attributes_pc_num',
      'client_company_company_attributes_established_at',
      'address1',
      'address2',
      'company_code',
      'tel',
      'client_company_company_attributes_status',
      'client_company_company_attributes_rank_status_id',
      'company_type_id',
      'juridical_personality_position',
      'bill_code',
      'apl',
      'pca',
      'fax',
      'email',
      'representative_role',
      'closing_day',
      'payment_type',
      'payment_month_type',
      'payment_day',
      'client_company_company_attributes_parent_company_id',
      'client_company_company_attributes_group_company_id'
    ];

    _.forEach(listId, function (value) {
      $(`#${value}`).val('').change();
    });

    $('input[name="client_company[company_attributes][id]"]').val('');
    if (companyId) {
      $.ajax({
        url: `/companies/${companyId}/load_company`,
        method: 'GET',
        success: function (res) {
          const company = res.company;

          $('input[name="client_company[company_attributes][id]"]').val(company.id);
          $('#client_company_note').val(company.note);
          $('#assigned_industry_ids').val(company.assigned_industry_ids).change();
          $('#name_kana').val(company.name_kana);
          $('#kintone_code').val(company.kintone_code);
          // $('#name').val(company.name);
          $('#representative_name').val(company.representative_name);
          $('#zipcode').val(company.zipcode);
          $('#capical').val(company.capical);
          $('#client_company_company_attributes_pc_num').val(company.pc_num);
          $('#client_company_company_attributes_established_at').val(res.established_at);
          $('#address1').val(company.address1);
          $('#address2').val(company.address2);
          $('#company_code').val(company.company_code);
          $('#tel').val(company.tel);
          $('#client_company_company_attributes_status_' + company.status).prop('checked', true);
          $('#client_company_company_attributes_rank_status_id_' + company.rank_status_id).prop('checked', true);
          $('#company_type_id').val(company.company_type_id).change();
          $('#juridical_personality_position').val(company.juridical_personality_position).change();
          $('#bill_code').val(company.bill_code);
          $('#apl').val(company.apl).change();
          $('#pca').val(company.pca).change();
          $('#fax').val(company.fax);
          $('#email').val(company.email);
          $('#representative_role').val(company.representative_role);
          $('#closing_day').val(company.closing_day).change();
          $('#payment_type').val(company.client_payment_type).change();
          $('#payment_month_type').val(company.payment_month_type).change();
          $('#payment_day').val(company.payment_day).change();
          $('#client_company_company_attributes_parent_company_id').val(company.parent_company_id).change();
          $('#client_company_company_attributes_group_company_id').val(company.group_company_id).change();
        },
      });
    }
  });

  $('#capical').on('keyup', function () {
    let valString = $(this).val();

    valString = valString.replace(/\,/g, '');
    x = valString.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';

    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }

    $(this).val(x1 + x2);
  });

  $('#capical').on('keypress paste', function (event) {
    const theEvent = event || window.event;
    if (theEvent.type === 'paste') {
      key = [];
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }

    const regex = /[0-9０-９]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  });

  $('.js-submit-update-client').on("click", function () {
    var $form = $("#client-company-edit-form");
    $form.find(".help-block").remove();
    $form.find(".has-error").removeClass("has-error");
    var id = $form.data("company-id");
    var actionURL = $form.attr("action");
    $.ajax({
      url: `/client_companies/${id}`,
      method: "PUT",
      dataType: "json",
      data: new FormData($form[0]),
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.status) {
          window.location.href = "/client_companies";
        } else {
          _.forEach(response.errors, function (errors, key) {
            if(key == 'category_company'){
              showDialogError(errors[0]);
            }else if(key.includes('company.')){
              var $inputElm = $form.find(`input[name='client_company[company_attributes][${key.replace('company.','')}]']`);
              $($inputElm).closest(".form-group").addClass("has-error");
              $($inputElm).closest(".form-group").append($("<span class='help-block'>" + errors[0] + "</span>"));
              addErrorTab($inputElm)
            }else{
              var $inputElm = $form.find(`input[name='company[${key}]']`);
              if ($inputElm) {
                $($inputElm).closest(".form-group").addClass("has-error");
                $($inputElm).closest(".form-group").append($("<span class='help-block'>" + errors[0] + "</span>"));
                addErrorTab($inputElm)
              }
            }
          });
          if (!_.isEmpty(response.nested_errors)) {
            $.each(response.nested_errors, function (modelNested, values) {
              $.each(values, function (key, errors) {
                $.each(errors, function (field, error) {
                  var $container = $('.card-sub-client')[key]
                  var $inputElement = $($container).find(".client_company_company_company_offices_" + field + " input")
                  if ($inputElement) {
                    $($inputElement).closest(".form-group").addClass("has-error");
                    $($inputElement).closest(".form-group").append($("<span class='help-block'>" + error[0] + "</span>"));
                    addErrorTab($inputElement)
                  }
                });
              });
            });
          }
        }
      }
    });
  })

  function addErrorTab($elm) {
    var dataTab = $($elm).closest(".wrap-form-content").data("tab");
    $(`li.${dataTab}`).addClass("has-error");
  }

  bindSortElm("#wrap-problem-company");
  bindSortElm("#sale-info-table");

  function bindSortElm($wrapElm) {
    $($wrapElm).find(".sort-item").on("click", function () {
      var sortType = $(this).data("sort-type");
      $($wrapElm).find(".sort-item").removeClass("d-none");
      $(this).addClass("d-none");

      var $people = $($wrapElm),
        $peopleli = $people.find('.nested-fields');
      $peopleli.sort(function (a, b) {
        var an = $(a).find(".js-effect-date").val();
        bn = $(b).find(".js-effect-date").val();

        if (sortType == "up") {
          if (an > bn) {
            return 1;
          }
          if (an < bn) {
            return -1;
          }
        } else {
          if (an > bn) {
            return -1;
          }
          if (an < bn) {
            return 1;
          }
        }

        return 0;
      });

      $peopleli.detach().appendTo($people);
    })
  }
});


function toggleMakerInfo($container) {
  $container.find(".maker-info").toggleClass("d-none");
  $container.find(".maker-container").find('[data-bs-target="#choose_maker"]').toggleClass("d-none");
}

function toggleModelInfo($container, showButton = false) {
  if (showButton) {
    $container.find(".model-info").addClass("d-none");
    $container.find(".model-container").find('[data-bs-target="#choose_model"]').removeClass("d-none");
    return;
  }
  $container.find(".model-info").toggleClass("d-none");
  $container.find(".model-container").find('[data-bs-target="#choose_model"]').toggleClass("d-none");
}


$(document).on('click', '[data-bs-target="#choose_maker"]', function (e) {
  window.makerContainer = $(e.target).closest("tr");
})

function resetModelDialog() {
  $("#choose_model #q_name_cont").val('');
  $('#choose_model [type="submit"]').trigger('click')
}

$(document).on('click', '#choose_maker .js-select-maker', function (e) {
  const makerId = $(this).data("maker-id");
  $(window.makerContainer).find("[data-maker-id]").val(makerId);
  $(window.makerContainer).find(".js-maker-name").html($(this).data("maker-name"));
  toggleMakerInfo($(window.makerContainer));
  $("#choose_model #maker_id").val(makerId);
  toggleModelInfo($(window.makerContainer), true);
  resetModelDialog();
})

$(document).on('click', '#choose_model .js-select-model', function (e) {
  const modelId = $(this).data("model-id");
  $(window.makerContainer).find("[data-model-id]").val(modelId);
  $(window.makerContainer).find(".js-model-name").html($(this).data("model-name"));
  $("#choose_model #model_id").val(modelId);
  resetModelDialog();
  toggleModelInfo($(window.makerContainer));
})

$(document).on('show.bs.modal', '#choose_maker', function () {
  let value = $("#choose_maker #q_name_cont").val();
  $("#choose_maker #q_name_cont").val('');
  $('#choose_maker [type="submit"]').trigger('click')
});

$(document).on('show.bs.modal', '#choose_model', function () {
  let value = $("#choose_model #q_name_cont").val();
  if (value.length > 0) {
    $("#choose_model #q_name_cont").val('');
    $('#choose_model [type="submit"]').trigger('click')
  }
});

$(document).on('click', '.close-model-info', function (e) {
  let $target = $(e.target.closest("tr"))
  toggleModelInfo($target);
  $target.find('[data-maker-id]').val('')

})

$(document).on('click', '.close-maker-info', function (e) {
  let $target = $(e.target.closest("tr"))
  toggleMakerInfo($target);
  toggleModelInfo($target, true);
  $target.find('[data-maker-id]').val('')
  $target.find('[data-model-id]').val('')
  $("#choose_maker .row-content").html('')
})

$(document).on('change', '.leasing_company', function () {
  let lease_company_id = $(this).find(':selected').val();
  var element = $(this).closest('tr').find('.object_lease_type')[0];
  $.ajax({
    data: { lease_company_id: lease_company_id },
    method: "get",
    dataType: 'json',
    url: '/client_companies/load_object_lease_types',
    success: function (e) {
      $(element).empty().attr('disabled', false);
      let data = e.map(function (item) {
        return { id: item.id, text: item.name }
      })

      $(element).select2({
        width: '100%',
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        data: data,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });
    }
  })
})
$('span[data-client-company-id]').on('click', function () {
    $('body').loading({
      message: '書類の取得...',
    });
    setTimeout(() => {
      $('body').loading('destroy');
    }, 3000);
});
// onpage loaded
$(document).ready(function () {
  $('.select2-multiple .select2').select2({
    width: '100%',
    theme: 'bootstrap',
    placeholder: '選択してください',
    // allowClear: true,
    language: {
      noResults: function () {
        return '見つかりません。';
      },
    },
  });

  $('.company-code-select').select2({
    width: '100%',
    theme: 'bootstrap',
    placeholder: '顧客表示名',
    allowClear: true,
    language: {
      noResults: function () {
        return 'データなし';
      },
    },
  });
});


$(document).ready(function () {
  $("#add-category-row").on("click", function () {
    let $rowTemplate = $("#categories-table tbody .category-row").last();
    let $newRow = $rowTemplate.clone();
    let $categoryType = $rowTemplate.find(".category-select").val().toLowerCase();
    let companyId = $rowTemplate.find(".company-code-select").val();
    let companyName = $rowTemplate.find(".company-code-select option:selected").text().trim();
    let companyCode = $rowTemplate.find(".company-code-select option:selected").data('company-code');

    if (companyId == '') {
      showDialogError("会社名を空白のままにすることはできません");
      return;
    }

    let $targetTable;
    if ($categoryType === "parent_company") {
        $targetTable = $("#parent-client tbody");
        if ($targetTable.find("tr").length > 0) {
          showDialogError('親会社タイプには 1 行のみが許可されます。')
          return;
        }
    } else if($categoryType === "group") {
        $targetTable = $("#group-client tbody");
        if ($targetTable.find("tr input[name='categories[][company_id]'][value='" + companyId + "']").length > 0) {
          showDialogError('グループタイプに重複した会社を追加することはできません')
          return;
        }
    }else if($categoryType === "office") {
      $targetTable = $("#office-client tbody");
      if ($targetTable.find("tr input[name='categories[][company_id]'][value='" + companyId + "']").length > 0) {
        showDialogError('オフィスタイプに重複した会社を追加することはできません')
        return;
      }
    }
    else if($categoryType === "other") {
      $targetTable = $("#other-client tbody");
      if ($targetTable.find("tr input[name='categories[][company_id]'][value='" + companyId + "']").length > 0) {
        showDialogError("重複する企業を「その他」カテゴリに追加することはできません。")
        return;
      }
    }
     else{
      showDialogError("有効なカテゴリタイプを選択してください。");
      return;
    }

    $newRow.find('.category-select').replaceWith(function () {
      return `
              <input type="text" class="form-control company-code-input" value="${companyCode}" disabled>
              <input type="text" name="categories[][category]" class="form-control category hidden" value="${$categoryType}" >
             `;
    });

    $newRow.find(".company-code-select").replaceWith(function () {
      return `
              <input type="text" class="form-control company-code-input" value="${companyName}" disabled>
              <input type="text" name="categories[][company_id]" class="form-control company-code-input hidden" value="${companyId}">
             `;
    });

    $newRow.find(".select2-container").remove();
    $newRow.find(".select2").removeClass("select2-hidden-accessible").removeClass("select2-container--bootstrap");
    $newRow.find(".add-category-row").closest("td").remove();


    let $deleteButton = `<td>
                          <a href="javascript:void(0)" class="btn btn-danger btn-delete-row">
                              <i class="fa fa-trash"></i>
                          </a>
                        </td>`;

    $newRow.append($deleteButton);

    $targetTable.append($newRow);

    $targetTable.on("click", ".btn-delete-row", function () {
      const $row = $(this).closest("tr");

      showDeleteConfirmation({}, function () {
        $row.remove();
      });
    });
  });

  $(document).on('click', '.remove-company-category', function () {
    const id = $(this).data('id');
    const companyOfficeId = $(this).data('office-id');
    const companyOtherId = $(this).data('other-id');
    const category = $(this).data('category');
    const $ele_company = $(this).closest('tr');

    // Sử dụng showDeleteConfirmation
    showDeleteConfirmation({}, function () {
      $.ajax({
        url: `/companies/${id}/remove_row_category`,
        method: 'PATCH',
        dataType: 'json',
        data: {
          category: category,
          company_office_id: companyOfficeId,
          company_other_id: companyOtherId,
        },
        success: function (res) {
          if (res.success === true) {
            showDialogSuccess(res.msg);
            $ele_company.remove();
          } else {
            showDialogError(res.error);
          }
        },
        error: function (err) {
          showDialogError('エラーが発生しました。もう一度試してください');
        },
      });
    });
  });



  $(document).on("change", ".company-code-select", function () {
      const companyID = $(this).val();
      const $row = $(this).closest("tr");

      $.ajax({
        url: `/companies/load_company_information`,
        method: "GET",
        data: { id: companyID },
        success: function (response) {
          if (response.success) {
              let companyInfo = response.data;
              $row.find('.company-name').val(companyInfo.company_name);

              const address1 = companyInfo.address1 || "";
              const address2 = companyInfo.address2 || "";
              let formattedAddress = "";
              if (address1 && address2) {
                  formattedAddress = `${address1} + ${address2}`;
              } else if (address1) {
                  formattedAddress = address1;
              } else if (address2) {
                  formattedAddress = address2;
              }

              $row.find('.company-address').val(formattedAddress);
              $row.find('.company-phone').val(companyInfo.tel);
          } else {
            showDialogError('会社が見つかりませんでした');
          }
        },
        error: function (xhr) {
            $row.find(".company-info").html(`<p>サーバーエラーが発生しました。</p>`);
        },
      });
  });
});


function calculateAge(birthdayStr) {
  const birthday = new Date(birthdayStr);
  const today = new Date();

  let age = today.getFullYear() - birthday.getFullYear();
  const monthDiff = today.getMonth() - birthday.getMonth();
  const dayDiff = today.getDate() - birthday.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
  }

  return age > 0 ? age : 1;
}


//Auto generate age
$(document).ready(function () {
  $('#client_company_company_attributes_representative_birthday').on('apply.daterangepicker', function () {
    const birthDateStr = $(this).val();
    const age = calculateAge(birthDateStr);
    $('#representative_age').val(age);
  });
});

// app/javascript/controllers/main_companies_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tel", "fax"];

  validateAndFormatTel(event) {
    const field = event.target;

    if (field === this.telTarget) {
      this.validateAndFormatField(this.telTarget);
    } else if (field === this.faxTarget) {
      this.validateAndFormatField(this.faxTarget);
    }
  }

  validateAndFormatField(field) {
    let input = field.value;

    let sanitizedInput = input.replace(/\D/g, "");

    if (input !== sanitizedInput) {
      field.value = sanitizedInput;
    }

    field.setCustomValidity("");

    this.formatField(field, sanitizedInput);
  }

  formatField(field, value) {

    let formattedValue = value.replace(
      /(\d{3})(\d{3})(\d{3})(\d{1,4})?$/,
      (_, p1, p2, p3, p4) => {
        return p1 + "-" + p2 + "-" + p3 + (p4 ? "-" + p4 : "");
      }
    );
    field.value = formattedValue;
  }
}
